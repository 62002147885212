import React, { useState, useRef, useEffect } from "react";
import {
  Play,
  Pause,
  SkipBack,
  SkipForward,
  RotateCcw,
  RotateCw,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "./Header";
import { fetchEpisodes, fetchEpisodeDetails } from "../utils/apiUtils";
import AudioPlayer from "./AudioPlayer";

const CustomProgressBar = ({ value, max, onChange }) => {
  const progressRef = useRef(null);

  const handleClick = (event) => {
    const rect = progressRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const clickedValue = (x / rect.width) * max;
    onChange(clickedValue);
  };

  const percentage = max > 0 ? (value / max) * 100 : 0;

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "42px",
          right: "32px",
        }}
      >
        <a href="/">
          <img src="/assets/logo.svg" width="85px" alt="Infographic" />
        </a>
      </div>
      <div
        ref={progressRef}
        className="relative w-full h-0.5 bg-gray-700 cursor-pointer"
        onClick={handleClick}
      >
        <div
          className="absolute top-0 left-0 h-full bg-gray-500"
          style={{ width: `${percentage}%` }}
        />
        <div
          className="absolute top-1/2 transform -translate-y-1/2"
          style={{ left: `${percentage}%` }}
        >
          <img
            src="/assets/logo.svg"
            style={{ width: "35px", height: "10px" }}
            alt="Progress"
            className="w-6 h-6 -ml-3"
          />
        </div>
      </div>
    </div>
  );
};

export default function THPodPlayer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [episode, setEpisode] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recentEpisodesStart, setRecentEpisodesStart] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchAndSetEpisodes = async () => {
      try {
        const allEpisodes = await fetchEpisodes();
        setEpisodes(allEpisodes);
      } catch (err) {
        console.error("Error fetching episodes:", err);
      }
    };

    fetchAndSetEpisodes();
  }, []);

  useEffect(() => {
    const fetchAndSetEpisodeDetails = async () => {
      if (!episode || episode.id !== parseInt(id)) {
        // Check if the episode is not loaded or if the ID has changed
        setLoading(true);
        try {
          const episodeDetails = await fetchEpisodeDetails(id);
          setEpisode(episodeDetails);
          setError(null);
          const currentIndex = episodes.findIndex(
            (ep) => ep.id === parseInt(id)
          );
          setRecentEpisodesStart(Math.max(0, currentIndex - 3));
        } catch (err) {
          console.error("Error fetching episode details:", err);
          setError("Error fetching episode details.");
        } finally {
          setLoading(false);
        }
      }
    };

    if (id) {
      fetchAndSetEpisodeDetails();
    } else {
      setError("No episode ID provided.");
      setLoading(false);
    }
  }, [id, episodes]); // Removed 'episode' from dependencies

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const skipToNextEpisode = () => {
    const currentIndex = episodes.findIndex((ep) => ep.id === parseInt(id));
    if (currentIndex !== -1 && currentIndex < episodes.length - 1) {
      const nextEpisodeId = episodes[currentIndex + 1].id;
      navigate(`/episode/${nextEpisodeId}`);
    }
  };

  const skipToPreviousEpisode = () => {
    const currentIndex = episodes.findIndex((ep) => ep.id === parseInt(id));
    if (currentIndex > 0) {
      const previousEpisodeId = episodes[currentIndex - 1].id;
      navigate(`/episode/${previousEpisodeId}`);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleProgressChange = (newTime) => {
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const skipForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(
        audioRef.current.currentTime + 15,
        duration
      );
    }
  };

  const skipBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(
        audioRef.current.currentTime - 15,
        0
      );
    }
  };

  const getRecentEpisodes = () => {
    return episodes.slice(recentEpisodesStart, recentEpisodesStart + 4);
  };

  const navigateRecentEpisodes = (direction) => {
    if (direction === "prev" && recentEpisodesStart > 0) {
      setRecentEpisodesStart(Math.max(0, recentEpisodesStart - 4));
    } else if (
      direction === "next" &&
      recentEpisodesStart < episodes.length - 4
    ) {
      setRecentEpisodesStart(
        Math.min(episodes.length - 4, recentEpisodesStart + 4)
      );
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-white">
        <img
          src="/assets/logo.svg"
          alt="Loading Logo"
          width={100}
          height={100}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-white">
        <p>{error}</p>
      </div>
    );
  }

  if (!episode) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-white">
        <p>No episode found.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <div className="flex flex-grow">
        <nav
          style={{
            position: "fixed",
            top: "50px",
            left: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            padding: "10px",
            borderRadius: "8px",
            zIndex: 1000,
          }}
        >
          <Header align="left" fontSize="20px" />
        </nav>
        <main
          className="flex-grow p-6 overflow-hidden"
          style={{
            paddingTop: "120px",
            paddingRight: "220px",
            paddingLeft: "220px",
            overflow: "hidden",
          }}
        >
          <div className="flex space-x-8">
            <div className="w-1/2">
              <img
                src={
                  episode.artwork_url || "/assets/episodePictures/default.jpg"
                }
                alt={episode.title || "Episode Artwork"}
                className="w-full h-full"
                style={{
                  width: "400px",
                  height: "400px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="w-1/2 space-y-4">
              <h1 className="text-3xl font-bold text-[#ff6b6b]">
                {episode.title || "Untitled Episode"}
              </h1>
              <div
                className="text-gray-300 overflow-y-auto font-size-13"
                style={{ height: "250px", fontSize: "13px" }} // Set a fixed height
              >
                <p>
                  {" "}
                  {episode.description
                    ? episode.description.replace(/<\/?[^>]+(>|$)/g, "")
                    : "No description available for this episode."}
                </p>
              </div>

              <div
                className="mt-6"
                style={{
                  position: "relative",
                  left: "-50px",
                  width: "500px",
                }}
              >
                {/* <h2 className="text-xl font-semibold mb-2">Recent Episodes</h2> */}
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => navigateRecentEpisodes("prev")}
                    disabled={recentEpisodesStart === 0}
                    className="p-1 bg-gray-800 rounded-full disabled:opacity-50"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <div className="flex space-x-4">
                    {getRecentEpisodes().map((ep) => (
                      <div
                        key={ep.id}
                        className="cursor-pointer"
                        onClick={() => navigate(`/episode/${ep.id}`)}
                      >
                        <img
                          src={
                            ep.artwork_url ||
                            "/assets/episodePictures/default.jpg"
                          }
                          alt={`Episode ${ep.id}`}
                          className="w-24 object-cover"
                        />
                        <p className="text-xs text-center mt-1">
                          {ep.title ? (
                            <span className="text-[#C5FF00]">
                              {ep.title
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                                .replace(":", "")}
                            </span>
                          ) : (
                            <span className="text-white">
                              <span className="text-[#C5FF00]">
                                {ep.id.toString().padStart(2, "0")}
                              </span>
                            </span>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => navigateRecentEpisodes("next")}
                    disabled={recentEpisodesStart >= episodes.length - 4}
                    className="p-1 bg-gray-800 rounded-full disabled:opacity-50"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <footer
        className="p-4"
        style={{
          paddingRight: "200px",
          paddingLeft: "200px",
        }}
      >
        <AudioPlayer
          audioUrl={episode.audio_url}
          onEnded={() => setIsPlaying(false)}
          onNext={skipToNextEpisode}
          onPrev={skipToPreviousEpisode}
        />
      </footer>

      <style jsx>{`
        /* Custom styles */
        ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background: #4a4a4a;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-track {
          background: #1a1a1a;
        }
      `}</style>
    </div>
  );
}
