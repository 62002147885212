import React, { useState, useRef, useEffect } from "react";

const CustomProgressBar = ({ value, max, onChange }) => {
  const progressRef = useRef(null);

  const handleClick = (event) => {
    const rect = progressRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const clickedValue = (x / rect.width) * max;
    onChange(clickedValue);
  };

  const percentage = max > 0 ? (value / max) * 100 : 0;

  return (
    <div
      ref={progressRef}
      className="relative w-full h-0.5 bg-gray-700 cursor-pointer"
      onClick={handleClick}
    >
      <div
        className="absolute top-0 left-0 h-full bg-gray-500"
        style={{ width: `${percentage}%` }}
      />
      <div
        className="absolute top-1/2 transform -translate-y-1/2"
        style={{ left: `${percentage}%` }}
      >
        <img
          src="/assets/logo.svg"
          style={{ width: "35px", height: "10px" }}
          alt="Progress"
          className="w-6 h-6 -ml-3"
        />
      </div>
    </div>
  );
};

const AudioPlayer = ({
  audioUrl,
  onEnded,
  onNext,
  onPrev,
  fontSize = "text-sm",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleProgressChange = (newTime) => {
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  const skipForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(
        audioRef.current.currentTime + 30,
        duration
      );
    }
  };

  const skipBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(
        audioRef.current.currentTime - 30,
        0
      );
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div>
      <CustomProgressBar
        value={currentTime}
        max={duration}
        onChange={handleProgressChange}
      />
      <div className="flex items-center justify-between mb-2">
        <span className={`text-[#C5FF00] font-medium ${fontSize}`}>
          {formatTime(currentTime)}
        </span>
        <span className={`text-gray-400 ${fontSize}`}>
          {formatTime(duration)}
        </span>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <button onClick={onNext}>
          <img src={"/assets/skipnext.svg"} className="w-5 h-5 transform scale-x-[-1]" />{" "}
        </button>
        <button onClick={skipBackward}>
          <img src={"/assets/replay.svg"} className="w-6 h-6" />{" "}
        </button>
        <button onClick={togglePlay}>
          <img
            src={isPlaying ? "/assets/play.svg" : "/assets/pause.svg"}
            alt={isPlaying ? "Pause" : "Play"}
            className="w-12 h-12"
          />
        </button>
        <button onClick={skipForward}>
          <img src={"/assets/skipforward.svg"} className="w-6 h-6" />{" "}
        </button>
        <button onClick={onPrev}>
          <img src={"/assets/skipnext.svg"} className="w-5 h-5" />{" "}
        </button>
      </div>
      <audio
        ref={audioRef}
        src={audioUrl}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => setDuration(audioRef.current?.duration || 0)}
        onEnded={() => {
          setIsPlaying(false);
          onEnded && onEnded();
        }}
      />
    </div>
  );
};

export default AudioPlayer;
