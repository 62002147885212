import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import { SearchIcon } from "lucide-react";
import {
  fetchEpisodes,
  preloadImages,
  fetchLatestEpisode,
} from "../utils/apiUtils";

export default function PodcastEpisodes() {
  const [episodes, setEpisodes] = useState([]);
  const [latestEpisode, setLatestEpisode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEpisodes, setFilteredEpisodes] = useState([]);
  const searchOverlayRef = useRef(null);

  useEffect(() => {
    const fetchAndSetEpisodes = async () => {
      setIsLoading(true);
      const episodesWithImages = await fetchEpisodes();
      await preloadImages(episodesWithImages);
      setEpisodes(episodesWithImages);
      setFilteredEpisodes(episodesWithImages);
      setIsLoading(false);
    };

    const fetchAndSetLatestEpisode = async () => {
      const latest = await fetchLatestEpisode();
      setLatestEpisode(latest);
    };

    fetchAndSetEpisodes();
    fetchAndSetLatestEpisode();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredEpisodes(episodes);
    } else {
      const filtered = episodes.filter((episode) =>
        episode.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredEpisodes(filtered);
    }
  }, [searchQuery, episodes]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchOverlayRef.current &&
        !searchOverlayRef.current.contains(event.target)
      ) {
        setIsSearchVisible(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    // The search is now handled in real-time by the useEffect hook
    setIsSearchVisible(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="h-screen flex justify-center items-center bg-black text-white">
          <img
            src="/assets/logo.svg"
            alt="Loading Logo"
            width={100}
            height={100}
          />
        </div>
      ) : (
        <div className="min-h-screen bg-black text-white">
          <nav className="w-[423px] p-8 sticky top-0">
            <Header align="left" fontSize="20px" />
          </nav>
          <div className="fixed top-8 right-8 z-50">
            <a href="/">
              <img
                src="/assets/logo.svg"
                alt="Infographic"
                width="85"
                height="100"
              />
            </a>
          </div>
          <button
            className="fixed top-10 right-36 z-50 bg-transparent border-none cursor-pointer"
            onClick={() => setIsSearchVisible(true)}
            aria-label="Open search"
          >
            <SearchIcon color="white" size={24} />
          </button>
          <div className="container mx-auto px-8 sm:px-16 lg:px-32 pb-16">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              {filteredEpisodes.length > 0 ? (
                filteredEpisodes.map((episode, index) => (
                  <Link
                    to={`/episode/${episode.id}`}
                    key={`${episode.id}-${index}`}
                    className="flex flex-col"
                  >
                    <div className="aspect-square overflow-hidden rounded-lg">
                      <img
                        src={episode.artwork_url}
                        alt={`Cover art for ${episode.title}`}
                        className="w-full h-full object-cover transition-transform hover:scale-105"
                      />
                    </div>
                    <h2 className="mt-2 text-sm font-bold text-white line-clamp-2">
                      {episode.title}
                    </h2>
                  </Link>
                ))
              ) : (
                <div className="col-span-full text-center text-xl mt-8">
                  No episodes found
                </div>
              )}
            </div>
          </div>
          {isSearchVisible && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="w-full max-w-3xl p-8" ref={searchOverlayRef}>
                <form onSubmit={handleSearch} className="relative">
                  <input
                    type="search"
                    placeholder="Search episodes..."
                    className="w-full pl-4 pr-12 py-3 text-lg bg-[#DAFF3E] rounded-full text-black placeholder-black/70"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    autoFocus
                  />
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
